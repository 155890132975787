.report-layout {
  padding: 20px;
  overflow-y: auto;
  height: 100%;
}
.report-layout .auditArea {
  position: absolute;
  right: 10px;
  top: 65px;
  height: 30px;
  background-color: white;
}
.report-layout .el-collapse {
  border-bottom: none;
}
.report-layout .el-collapse .el-collapse-item {
  margin-bottom: 15px;
}
.report-layout .el-collapse /deep/ .el-collapse-item__header {
  padding-left: 15px;
  font-size: 16px;
  background-color: #eff6fe;
}
.report-layout .el-collapse /deep/ .el-collapse-item__wrap {
  border-bottom: none;
}
.report-layout .el-collapse /deep/ .el-collapse-item__content {
  padding: 15px;
}
.report-layout .view-form .el-col {
  padding: 10px !important;
  border: 2px solid #eee;
  margin-top: -1px;
  margin-left: -2px;
  word-break: break-all;
}
.report-layout .view-form .el-col-24 {
  display: flex;
  padding: 0 !important;
  border: none !important;
}
.report-layout .view-form .el-col-4 {
  text-align: right;
  background-color: #f7f7f7;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.report-layout .ml0 {
  margin-left: 0 !important;
  border-left: none !important;
}
.btn {
  padding: 0 50px !important;
}
.red {
  color: red;
}
.editor /deep/ .content img {
  width: 100%;
}
.tip-box {
  line-height: 25px;
  background: #ecf5ff;
  border-radius: 5px;
  border: 1px solid #d9ecff;
  color: #419eff;
  padding: 5px 15px;
}
.tip-box .left-indent {
  text-indent: 3.5rem;
}
